@font-face{
  font-family: "Avenir-Medium";
  src: local('Avenir-Medium'), url("./fonts/AvenirMedium.ttf") format("truetype");
}

body {
  font-family: Avenir-Medium, Arial, Helvetica, sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #64C8FF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 1vmin);
  color: white;
  margin-bottom: 25px;
}

.AudioHeader {
  font-size: calc(15px + 2vmin);
  color: white;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.CategoryButton {
  float: right;
  background-color: #F8FAEF;
  color: #0089BF;
  border-radius: 0px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-width: 0px;
  width: 85%;
  font-size: calc(12px + 1vmin);
  text-decoration: none;
  text-align: left;
  animation-name: SlideIn;
  animation-duration: 500ms;
}

.HomeRowButton {
  font-size: calc(18px + 2vmin);
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}

.AboutText {
  font-size: calc(12px + 1vmin);
  text-align: left;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
}

.AppLogo {
  height: 100%;
  justify-self: center;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
}

.subtitle {
  color: white;
  margin-top: 20px;
  margin-bottom: 55px;
}

.AppLogoHome {
  /* height: 200px; */
  width: 70%;
  max-width: 250px;
  margin-top: 20px;
  margin-bottom: 25px;
}

.ContentRowImage {
  height: 35px;
  margin-right: 5px;
  margin-left: -5px;
  margin-top: -3px;
}

.HomeRowImage {
  height: 50px;
  margin-right: 10px;
}

.tagslist-btn {
  color: white;
}

.navbar {
  background-color: #2193cf;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: #EADDDF;
}

.nav-menu {
  background-color: #63B3FF;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 2;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-menu-underlay {
  position: absolute;
  left: 0;
  background-color: rgba(255, 255, 255, 0.0);
  width: 0;
  height: 0;
  z-index: 1;
}

.nav-menu-underlay.active {
  width: 100vw;
  height: 100vh;
  /* background-color: magenta;
  opacity: .5; */
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
  margin-left: -25px;
}

.nav-text img {
  height: 40px;
  margin-right: -8px;
}

.nav-text a {
  text-decoration: none;
  color:#F8FAEF;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #ffffff;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #63B3FF;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes SlideIn {
  from { transform: translateX(100%) }
  to { transform: translate(0%) }
}
